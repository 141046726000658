import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts'
import GBanner from '../images/Genesis1.png'
import Collapsible from 'react-collapsible'

const GenesisSales = () => (
	<Layout head={{ title: 'ProGrowth Rapid Growth Sales Plan' }}>
		<div className="gsp">
			<div
				id="hero-section"
				className="bg-cover"
				style={{ background: `url("${GBanner}")` }}
			>
				<div className="container">
					<div className="slim-content">
						<h1 className="text-red">ProGrowth Rapid Growth Sales Plan</h1>
						<p className="py-4">
							Sales Strategy / Sales Process / Sales Infrastructure and Tools /
							Sales Execution
						</p>
					</div>
				</div>
			</div>
			<div className="container py-5">
				<p>Do you need to revive stagnant sales?</p>
				<p>Are you growing but need help scaling?</p>
				<p>
					Do you need effective sales processes, infrastructure and a plan to
					scale?
				</p>
				<p>
					Do you struggle with finding the right sales people and not getting
					the results you would like from your sales team?
				</p>
				<p>
					Are you considering selling your company and want to increase sales
					growth to increase the value and multiple of your company?
				</p>
				<p>
					If you answered yes to any of these questions – contact ProGrowth
					Advisors for a free consultation at{' '}
					<a href="tel:512-696-1333" target="_blank" rel="noopener noreferrer">
						512-696-1333
					</a>{' '}
					or{' '}
					<a
						href="mailto:Joe@ProGrowthAdvisors.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						Joe@ProGrowthAdvisors.com
					</a>
				</p>

				<center>
					<h3 className="text-red my-4">
						Not sure if you need help? Take the 10 Question FREE Sales
						Assessment to see how ProGrowth Advisors can help you.
					</h3>
					<a
						className="btn btn-primary red-btn btn-lg"
						href="https://www.salesxceleration.com/take-sales-agility-assessment/?gf_advisor_slug=joe-dauskurdas"
						target="_blank"
						rel="noopener noreferrer"
					>
						FREE ASSESSMENT
					</a>
				</center>

				<h3 className="my-4">
					We will build and execute a plan that will guarantee your company’s
					growth!
				</h3>
				<p>
					<span className="text-red">The ProGrowth Sales Growth Plan</span>{' '}
					leverages the proven{' '}
					<a
						href="https://www.salesxceleration.com/what-we-do/genesis-sales-plan/"
						target="_blank"
						rel="noreferrer noopener"
					>
						Sales Xceleration Platform
					</a>
					, which has been developed by the collective experience of 80+ Senior
					Sales Executive Leaders. We utilized core tools and execute based on
					our own extensive hands-on experience developing and executing winning
					sales growth strategies.
				</p>
				<h6 className="my-4">
					Take the first step and contact ProGrowth Advisors for a free sales
					growth consultation at{' '}
					<a href="tel:512-696-1333" target="_blank" rel="noreferrer noopener">
						512-696-1333
					</a>{' '}
					or email Founder Joe Dauskurdas at{' '}
					<a
						href="mailto:Joe@ProGrowthAdvisors.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						Joe@ProGrowthAdvisors.com
					</a>
					.
				</h6>
				<h3>The 3 Pillars of Sales Growth Plan:</h3>
				<div className="bg-grey my-4">
					<Collapsible trigger="Sales Strategy" open>
						<ul className="circle-chevron">
							<li>
								Leverage business intelligence to increase sales growth rate.
							</li>
							<li>Locate the best clients and demographics.</li>
							<li>
								Identify best go to market, channel and geographic strategies.
							</li>
							<li>
								Create a winning “customer validated” value proposition and
								winning messaging.
							</li>
						</ul>
					</Collapsible>
					<Collapsible trigger="Sales Process & Enablement">
						<ul className="circle-chevron">
							<li>Maximize each business opportunity.</li>
							<li>
								Create sales and marketing processes that are understood and
								used by everyone.
							</li>
							<li>
								Integrate and optimize “best practice” sales tools to maximize
								sales efficiency.
							</li>
						</ul>
					</Collapsible>
					<Collapsible trigger="Sales Execution">
						<ul className="circle-chevron">
							<li>Precisely define goals for sales and business growth.</li>
							<li>
								Define proper sales organization and compensation to drive
								results.
							</li>
							<li>
								Create forecasting and pipeline and management tools to lead and
								sustain growth.
							</li>
						</ul>
					</Collapsible>
				</div>

				<blockquote class="blockquote text-center mt-5">
					<p class="mb-0">
						“Growing companies and sales does not happen by accident and cannot
						be sustained through heroic leadership – it is the relentless daily
						practice of proven methodologies and disciplines, along with
						continual testing and validating new growth concepts.”
					</p>
					<footer class="text-secondary">
						Joe Dauskurdas{' '}
						<cite title="Source Title">– Founder ProGrowth Advisors</cite>
					</footer>
				</blockquote>
				<center className="my-4">
					<Link to="/about" className="btn btn-primary red-btn">
						Meet The Founder
					</Link>
				</center>
			</div>
		</div>
	</Layout>
)

export default GenesisSales
